import http from '~/http-common'
import { ApiPath } from '~/enum/apiPath'
import { defineStore } from 'pinia'
import { inject, ref } from 'vue'
import { updateIsBackgroundLoadingKey } from '~/const/updateIsBackgroundLoadingKey'
import * as Sentry from '@sentry/vue'
import { type AxiosResponse } from 'axios'
import { type HttpResponseData } from '~~/types/httpResponseData'

interface UnreadCountResData extends HttpResponseData {
  noticeCount: number,
  eventCount: number,
  channelCount: number,
}

interface UnreadCountRes extends AxiosResponse {
  data: UnreadCountResData,
}

const UNREAD_COUNT_STORE_KEY = 'UnreadCountStore'

export const useUnreadCountStore = defineStore(UNREAD_COUNT_STORE_KEY, () => {

  /**
   *  未読件数(お知らせ)
   */
  const noticeCount = ref(0)
  /**
   *  未読件数(イベント)
   */
  const eventCount = ref(0)

  /**
   * 未読件数(お知らせ)を返却する処理
   */
  const getNoticeCount = () => {
    return noticeCount.value
  }

  const getNoticeAndEventCount = () => {
    return noticeCount.value + eventCount.value
  }

  const setNoticeCount = (count: number) => {
    noticeCount.value = count
  }

  const setEventCount = (count: number) => {
    eventCount.value = count
  }

  const resetUnreadCount = () => {
    setNoticeCount(0)
    setEventCount(0)
  }

  /**
   * APIから未読件数を取得する処理
   */
  const loadUnreadCount = (stuId: string) => {
    const path = ApiPath.UNREAD_COUNT_MODULE_GET_UNREAD_COUNT + '?stuId=' + stuId
    const headers = {
      mode: 'cors',
      credentials: 'include',
      csrfToken: sessionStorage.getItem('token')!,
    }
    const backgroundLoading = inject(updateIsBackgroundLoadingKey)!
    backgroundLoading?.('loadUnreadCount', true)

    http.get(path, { headers: headers }).then((response: UnreadCountRes) => {
      noticeCount.value = response.data.noticeCount
      eventCount.value = response.data.eventCount
    })
    .catch((error) => {
      // Sentryにエラーを送信
      Sentry.captureException(error)
    })
    .finally(() => {
      backgroundLoading?.('loadUnreadCount', true)
    })
  }

  return {
    noticeCount,
    eventCount,
    getNoticeCount,
    getNoticeAndEventCount,
    setNoticeCount,
    setEventCount,
    resetUnreadCount,
    loadUnreadCount,
  }
}, {
  persist: {
    storage: sessionStorage,
  }
})
